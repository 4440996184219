import Clipboard from '../../../../vendor/assets/javascripts/clipboard.min.js'

$ ->
  if $('.copy-link').length
    clipboard = new Clipboard('.copy-link');

    $('.copy-link').tooltip({
      placement: "right",
      title: "Copied!",
      trigger: "manual",
      container: 'body',
      template: '<div class="tooltip copy-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
    })

    $('.copy-link').mouseout (e) ->
      # Hide tooltip when mouse leaves
      $(e.target).tooltip("hide");

    clipboard.on 'success', (e) ->
      # Show the tooltip after copying
      $(e.trigger).tooltip('show');
      # Clear selected text
      e.clearSelection();
