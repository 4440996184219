import '../../src/public/clipboard.coffee'
import '../../stylesheets/public/citations.scss'

$ ->
  # Change search placeholder according to which category is selected
  $(".home-search-form .searcher-category").on "change", (e) ->
    if (e.currentTarget.value == "drugs")
      $(".home-search-form #query").prop("placeholder", "Tylenol");
    else if (e.currentTarget.value == "targets")
      $(".home-search-form #query").prop("placeholder", "Histamine Receptor");
    else if (e.currentTarget.value == "pathways")
      $(".home-search-form #query").prop("placeholder", "Benazepril");
    else if (e.currentTarget.value == "indications")
      $(".home-search-form #query").prop("placeholder", "Heart Disease");

# Kind of hacky, but in order to make the carousel work smoothly we need to explicitly
# set the height so our variable size slides can stretch to fit.
# In order to calculate the max height we need to temporarily make all the slides visible
# for javascript.
setCarouselHeights = (id) ->
  items = $(id).find(".carousel-item");
  
  # Display all slides, but invisible and absolutely positioned (so other content doesn't move)
  items.css({
    visibility: 'hidden',
    display:    'flex',
    height:     'auto'
  });

  # Get the max height
  max_height = Math.max.apply(null, items.map (i) -> return $(this).outerHeight(); );

  # Hide everything again
  items.attr('style', '');
  # Set the carousel max height
  $(id).find(".carousel-inner").css({ height: max_height });

$ ->
  setCarouselHeights("#news-carousel");

  window.addEventListener "resize", () ->
    setCarouselHeights("#news-carousel");

$ ->
  $('.multi-carousel').on 'slide.bs.carousel', (e) ->
    carousel_id = "#" + $(this).attr("id");
    totalItems = $(carousel_id + " .carousel-item").length;
    itemsPerSlide = parseInt($(this).data("items"));
    
    # If we have more items that we can display at one time, we need to pop the end item
    # and add to the start (or vice versa) when we carousel
    if (totalItems > itemsPerSlide)
      $e = $(e.relatedTarget);
      idx = $e.index();
      totalItems = $(carousel_id + " .carousel-item").length;

      if (idx >= totalItems-(itemsPerSlide-1))
        if (e.direction == "left")
          $(carousel_id + " .carousel-item").eq(0).appendTo(carousel_id + " .carousel-inner");
        else
          $(carousel_id + " .carousel-item").eq(totalItems - 1).prependTo(carousel_id + " .carousel-inner");

shrinkSearchbar = ->
  searchBar = $('.pages-c.home-a .search-bar')
  searchBarContainer = $('.pages-c.home-a .search-bar-container')

  # If the navbar should be collapsed
  if $(window).scrollTop() > 0
    searchBar.removeClass 'search-bar--large'
    searchBarContainer.addClass 'shrink'
    window.navbar_height = $("header").outerHeight()
  else
    searchBar.addClass 'search-bar--large'
    searchBarContainer.removeClass 'shrink'
    window.navbar_height = $("header").outerHeight()
  return

# Custom video autoplay
# The default html video autoplay will play the video even if the video peaks into the viewport ever so slightly.
# This may result in the user missing the first few seconds of a video until they scroll it into view.
# The default behaviour also didn't have the ability to delay the autoplay.
# To avoid all this, this custom autoplay method is used instead.
# At the moment, this is only used for the `.technology-video` on the homepage. If this is ever needed elsewhere,
# feel free to refactor the selector to something more reusable. Or refactor all this into a component.
videoAutoPlay = ->
  video = $('.technology-video')

  # Play video when atleast half of it is visible
  playThreshold = 0.5
  # Pause video when none of it is visible
  pauseThreshold = 0

  videoDOM = video[0]

  # Using a `starting` attribute on the DOM to flag whether are not a video is starting or not due to the delay from setTimeout
  if videoInPlayView(video, playThreshold) && videoDOM.paused && !videoDOM.starting
    # Calling `videoInPlayView` again to check if video is still in view or if the user just scrolled past the video
    setTimeout (->
      if videoInPlayView(video, playThreshold)
        videoDOM.play()
      videoDOM.starting = false
    ), 1000
    videoDOM.starting = true
  else if videoInPauseView(video, pauseThreshold) && !videoDOM.paused
    videoDOM.pause()

  return

videoInPlayView = (video, threshold) ->
  videoDOM = video[0]
  videoRect = videoDOM.getBoundingClientRect()
  videoHeight = video.outerHeight()
  windowHeight = window.innerHeight || document.documentElement.clientHeight

  return videoRect.bottom >= navbar_height + (videoHeight * threshold) &&
         videoRect.bottom <= windowHeight + (videoHeight * threshold)

videoInPauseView = (video, threshold) ->
  videoDOM = video[0]
  videoRect = videoDOM.getBoundingClientRect()
  videoHeight = video.outerHeight()
  windowHeight = window.innerHeight || document.documentElement.clientHeight

  return videoRect.bottom <= navbar_height + (videoHeight * threshold) ||
         videoRect.bottom >= windowHeight + (videoHeight * (1 - threshold))

$ ->
  if window.location.pathname == '/'
    $(window).on 'scroll resize', ->
      shrinkSearchbar()
      videoAutoPlay()
      return

    # initial set
    shrinkSearchbar()
    videoAutoPlay()
    return

$ ->
  $('#blogCarousel').carousel({
    interval: false,
  });
